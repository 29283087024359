.admin-section header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f0f0f0;
  }
  
  .admin-section nav ul {
    display: flex;
    list-style-type: none;
    padding: 0;
  }
  
  .admin-section nav ul li {
    margin-right: 1rem;
  }
  
  .admin-section nav button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .admin-section nav button.active {
    background-color: #0056b3;
  }
  
  .admin-section main {
    padding: 1rem;
  }
  
  .admin-panel {
    padding: 20px;
  }
  
  .tab-section {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 2px solid #3498db;
    width: fit-content;
  }
  
  .tab {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #e0e0e0;
    color: #333;
    border: none;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    margin-right: 2px;
    position: relative;
    top: 2px;
  }
  
  .tab.active {
    background-color: #fff;
    color: #3498db;
    border: 2px solid #3498db;
    border-bottom: 2px solid #fff;
  }
  
  .admin-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    padding: 10px 0;
  }
  
  .admin-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #e0e0e0;
    color: #333;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .admin-button.active {
    background-color: #3498db;
    color: white;
  }
  
  .reports-content, .admin-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .admin-panel {
    padding: 20px;
  }
  
  .admin-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: #3498db;
    color: white;
    position: relative;
  }
  
  .admin-header h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .admin-header .profile-menu {
    position: absolute;
    right: 20px;
  }
  

  
  .admin-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: transparent;
    color: #333;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .admin-buttons button:last-child {
    margin-right: 0;
  }
  
  .admin-buttons button.active {
    background-color: #3498db;
    color: white;
  }
  
  .admin-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .admin-home {
    padding: 20px;
  }
  
  .chart-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .chart-controls {
    margin-bottom: 20px;
  }
  
  .chart-controls select {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .section-title {
    font-size: 28px;  /* Increase the font size */
    text-align: center;  /* Center the text */
    margin-bottom: 30px;  /* Add some space below the title */
    color: #3498db;  /* Optional: change the color to match your theme */
    font-weight: bold;  /* Optional: make the font bold */
  }