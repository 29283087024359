@import '../styles/variables';

.zone-location-selector {
  &-container {
    max-width: 1200px;
    margin: 0 auto;
    font-family: $font-family;
  }

  &-header {
    background-color: $primary-color;
    color: $white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h1 {
      margin: 0;
      font-size: 24px;
      flex-grow: 1;
      text-align: center;
    }
  }

  &-content-box {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

  &-page-title {
    color: $primary-color;
    margin-top: 0;
    font-size: 24px;
    text-align: center;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  &-content-layout {
    display: flex;
    gap: 20px;

    @media (max-width: $small-mobile-breakpoint) {
      flex-direction: column;
      gap: 5px;
      
      .wizard-navigation {
        order: -1;
        margin: -20px -20px 0;
        background-color: $gray-light;
      }
    }
  }

  &-main-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: $small-mobile-breakpoint) {
      width: 100%;
      padding-top: 10px;
    }
  }

  &-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-row {
      display: flex;
      justify-content: space-between;
      gap: 20px; // This creates the same gap as between the buttons
      margin-bottom: 20px;
    }

    &-group {
      flex: 1; // This makes both groups grow to fill available space
      width: calc(50% - 10px); // 50% width minus half of the gap

      &--date,
      &--location {
        flex: 1; // Allow flex grow
      }
    }
  }

  &-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &-input,
  &-select {
    width: 100%;
    padding: 8px;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: $font-size-base;
    box-sizing: border-box; // This ensures padding is included in the width

    &:disabled {
      background-color: #f0f0f0;
      cursor: not-allowed;
    }
  }

  // Specific style for date input to match select width
  &-input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 10px; // Adjust this value to match the select arrow space
  }

  &-error-message {
    color: $error-color;
    margin-top: 10px;
  }

  &-navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px; // This creates a small gap between the buttons
  }

  &-button {
    flex: 1; // This makes both buttons grow to fill available space
    padding: 10px 20px;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font-size-base;
    text-align: center; // Ensures text is centered in the button

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &-additional-info {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: $text-color;

    a {
      color: $primary-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
