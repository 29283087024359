.activity-details {
  padding: 20px;
  
  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }

  .detail-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    h4 {
      color: #2c3e50;
      margin-bottom: 15px;
      font-size: 1.1em;
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 8px;
    }

    p {
      margin-bottom: 12px;
      line-height: 1.5;
      color: #495057;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li {
        padding: 8px 0;
        border-bottom: 1px solid #dee2e6;
        line-height: 1.5;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  // Nested items within sections (like placed literature items)
  div > div {
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid #e9ecef;

    &:last-child {
      margin-bottom: 0;
    }
  }
}