.admin-home {
    padding: 20px;
  
    .chart-controls {
      margin-bottom: 20px;
  
      select {
        padding: 5px 10px;
        font-size: 16px;
      }
    }
  
    .charts-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .chart-item {
        width: 32%;
        margin-bottom: 20px;
        height: 300px; // Adjust this value as needed
      }
    }
  }
  
  // Responsive design
  @media (max-width: 1200px) {
    .admin-home .charts-container .chart-item {
      width: 48%;
    }
  }
  
  @media (max-width: 768px) {
    .admin-home .charts-container .chart-item {
      width: 100%;
    }
  }