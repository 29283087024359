@import '../styles/variables';

.additional-service-info {
    &-container {
        max-width: 1200px;
        margin: 0 auto;
        font-family: $font-family;
    }

    &-header {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        h1 {
            margin: 0;
            font-size: 24px;
            flex-grow: 1;
            text-align: center;
        }
    }

    &-content-box {
        background-color: $white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    &-page-title {
        color: $primary-color;
        margin-top: 0;
        font-size: 24px;
        text-align: center;
        border-bottom: 2px solid $primary-color;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    &-content-layout {
        display: flex;
        gap: 20px;

        @media (max-width: $small-mobile-breakpoint) {
            flex-direction: column;
            gap: 5px;
            
            .wizard-navigation {
                order: -1;
                margin: -20px -20px 0;
                background-color: $gray-light;
            }
        }
    }

    &-main-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (max-width: $small-mobile-breakpoint) {
            width: 100%;
            padding-top: 10px;
        }
    }

    &-form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-info-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            font-weight: bold;
            margin: 0 0 10px 0;
        }
    }

    &-input-group {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &-radio-group {
        display: flex;
        gap: 10px;

        button {
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            font-weight: bold;
            border-radius: 5px;
            font-size: 16px;
            transition: background-color 0.3s, color 0.3s;

            &.active {
                background-color: $primary-color;
                color: $white;
            }

            &:not(.active) {
                background-color: #ecf0f1;
                color: #7f8c8d;
            }
        }
    }

    &-input,
    &-select {
        width: 200px;
        padding: 8px;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: $font-size-base;
        box-sizing: border-box;

        &.error {
            border-color: $error-color;
            background-color: lighten($error-color, 35%);
        }
    }

    &-navigation-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        gap: 20px;
    }

    &-button {
        flex: 1;
        padding: 10px 20px;
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: $font-size-base;
        text-align: center;

        &:hover {
            background-color: darken($primary-color, 10%);
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &-additional-info {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        color: $text-color;

        a {
            color: $primary-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// Add these variables to your _variables.scss file or at the top of this file
$error-color: #ff0000;