@import '../../styles/variables';

.admin-page {
    max-width: 1200px;
    margin: 0 auto;
    font-family: $font-family;

    &-content {
        background-color: $white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        h2 {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .language-controls {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;

        select {
            flex-grow: 1;
        }
    }

    .language-details-box {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: 20px;
        margin-bottom: 20px;

        h3 {
            margin-top: 0;
            margin-bottom: 15px;
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;
        }

        .language-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .language-details {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .sort-order-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .sort-order-input {
                        width: 60px;
                        padding: 4px;
                        border: 1px solid $border-color;
                        border-radius: 4px;
                        text-align: center;

                        &:focus {
                            outline: none;
                            border-color: $primary-color;
                            box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
                        }
                    }
                }
            }

            .language-actions {
                display: flex;
                gap: 10px;
            }
        }
    }

    .sections-container {
        display: flex;
        gap: 20px;

        .literature-types-section,
        .literature-names-section {
            flex: 1;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            padding: 20px;

            h3 {
                margin-top: 0;
                margin-bottom: 15px;
            }

            .button-container {
                margin-bottom: 15px;
            }
        }
    }

    .admin-page-grid {
        display: grid;
        grid-template-columns: 1fr 100px auto;
        gap: 10px;
        align-items: center;

        .admin-page-grid-header {
            font-weight: bold;
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;
            margin-bottom: 10px;
            display: contents;

            span {
                padding: 10px;
            }
        }

        .admin-page-grid-item {
            display: contents;

            span, .action-buttons {
                padding: 10px;
            }

            .sort-order-input {
                width: 60px;
                padding: 4px;
                border: 1px solid $border-color;
                border-radius: 4px;
                text-align: center;

                &:focus {
                    outline: none;
                    border-color: $primary-color;
                    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
                }
            }

            .selectable-type {
                cursor: pointer;
                &:hover {
                    background-color: $gray-light;
                }
            }

            &.selected {
                background-color: lighten($primary-color, 35%);
            }

            .action-buttons {
                display: flex;
                gap: 5px;
                justify-content: flex-end;
            }
        }
    }

    &-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &-content {
            background-color: $white;
            padding: 20px;
            border-radius: $border-radius;
            width: 300px;

            h3 {
                margin-top: 0;
                margin-bottom: 15px;
            }

            input {
                width: 100%;
                padding: 5px;
                margin-bottom: 15px;
            }

            .modal-actions {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
            }
        }
    }

    &-button {
        padding: 5px 10px;
        cursor: pointer;
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 4px;

        &:hover {
            background-color: darken($primary-color, 10%);
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &.delete {
            background-color: $danger-color;

            &:hover {
                background-color: darken($danger-color, 10%);
            }
        }
    }
}