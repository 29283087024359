@import '../styles/variables';

.profile-menu {
  display: inline-block;
  position: relative;

  .profile-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-icon-circle {
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    svg {
      width: 16px;
      height: 16px;
      color: $white;
    }
  }

  &-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 150px;
  }

  .dropdown-menu {
    @extend .profile-menu-content;
  }

  .menu-item {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: $text-color;

    &:hover {
      background-color: $background-color;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }

  .modal-content {
    background-color: $white;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 80%;
    max-width: 800px;
    color: $text-color;
    position: relative;
    overflow-y: auto;
    max-height: 90vh;

    h3 {
      margin-top: 0;
      color: $primary-color;
      font-size: 28px;
      margin-bottom: 30px;
    }

    p {
      margin: 20px 0;
      font-size: 18px;
    }
  }

  .language-selector {
    margin: 30px 0;

    label {
      display: block;
      margin-bottom: 10px;
      color: $text-color;
      font-weight: bold;
      font-size: 18px;
    }

    select {
      width: 100%;
      padding: 12px;
      border: 1px solid $border-color;
      border-radius: 6px;
      font-size: 16px;
    }
  }

  .save-button,
  .close-button {
    margin-top: 30px;
    padding: 12px 24px;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  .save-button {
    margin-right: 20px;
  }

  .confirmation-modal {
    @extend .modal-overlay;

    .modal-content {
      p {
        margin-bottom: 20px;
      }

      button {
        margin-right: 10px;
        padding: 10px 20px;
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: darken($primary-color, 10%);
        }

        &:last-child {
          background-color: $error-color;

          &:hover {
            background-color: darken($error-color, 10%);
          }
        }
      }
    }
  }
}
