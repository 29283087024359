@import 'variables';

.admin-page {
  max-width: 100%; // Changed from 1200px to 100%
  margin: 0 auto;
  font-family: $font-family;
  padding: 0 20px; // Add some padding on the sides

  &-header {
    background-color: $primary-color;
    color: $white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
      font-size: 24px;
    }
  }

  .admin-nav {
    background-color: $gray-light;
    padding: 10px 0;
    margin-bottom: 20px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;

      li {
        margin: 0 10px;

        a {
          text-decoration: none;
          color: $text-color;
          padding: 5px 10px;

          &.active {
            background-color: $primary-color;
            color: $white;
            border-radius: 4px;
          }
        }
      }
    }
  }

  &-content {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; // Ensure the content uses full width
    max-width: none; // Remove any max-width restriction

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .zone-controls {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;

    select {
      flex-grow: 1;
    }
  }

  .zone-details-box {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 20px;
    margin-bottom: 20px;

    h3 {
      margin-top: 0;
      margin-bottom: 15px;
      border-bottom: 1px solid $border-color;
      padding-bottom: 10px;
    }

    .zone-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .zone-actions {
        display: flex;
        gap: 10px;
      }
    }

    .email-section {
      display: flex;
      align-items: center;
      gap: 10px;

      input[type="email"] {
        flex-grow: 1;
      }
    }
  }

  .locations-section,
  .shifts-section {
    flex: 1;
    
    h3 {
      margin-top: 0;
      margin-bottom: 15px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  .sections-container {
    display: flex;
    gap: 20px;
  }

  .locations-section {
    flex: 7; // 70% width
  }

  .shifts-section {
    flex: 3; // 30% width
  }

  .locations-section,
  .shifts-section {
    h3 {
      margin-top: 0;
      margin-bottom: 15px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  &-grid {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 1fr 100px auto;
      padding: 10px;
      border-bottom: 1px solid $border-color;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      > * {
        padding: 0 10px;
        &:not(:last-child) {
          border-right: 1px solid $border-color;
        }
      }
    }

    &-header {
      background-color: $gray-light;
      font-weight: bold;
    }

    &-item {
      &.selected {
        background-color: lighten($primary-color, 35%);
      }

      .action-buttons {
        display: flex;
        gap: 5px;
        justify-content: flex-end;
      }
    }
  }

  &-button {
    padding: 5px 10px;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &.delete {
      background-color: $danger-color;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }
  }

  &-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      background-color: $white;
      padding: 20px;
      border-radius: 4px;
      width: 400px; // Increased width

      h3 {
        margin-top: 0;
        margin-bottom: 20px;
      }

      label {
        display: block;
        margin-bottom: 15px;

        input {
          display: block;
          width: 100%;
          margin-top: 5px;
          padding: 8px;
          border: 1px solid $border-color;
          border-radius: $border-radius;
          font-size: 16px;
        }
      }

      .modal-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;
      }
    }
  }
}

// If you want to maintain some maximum width for very large screens, you can add:
@media (min-width: $breakpoint-xl) {
  .admin-page {
    max-width: 1600px; // Or any other large value that suits your design
  }
}