@import '../styles/variables';

.login-container {
  @include flex-center;
  flex-direction: column;
  height: 100vh;
  background-color: $background-color;
}

.login-box {
  background-color: $white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: $container-width;

  h2 {
    text-align: center;
    color: $primary-color;
    margin-bottom: 20px;
  }
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    display: block;
    margin-bottom: 5px;
    color: $primary-color;
    width: 100%;
    text-align: left;
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid $border-color;
    border-radius: 4px;
    background-color: $background-color;
  }
}

.login-button {
  @include button-reset;
  width: 100%;
  padding: 10px;
  background-color: $primary-color;
  color: $white;
  border-radius: 4px;
  font-size: $font-size-base;

  &:hover {
    background-color: darken($primary-color, 5%);
  }
}

.separator {
  @include flex-center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $border-color;
  }

  &::before {
    margin-right: .25em;
  }

  &::after {
    margin-left: .25em;
  }
}

.google-button {
  @include button-reset;
  width: 100%;
  padding: 10px;
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 4px;
  font-size: $font-size-base;
  @include flex-center;

  img {
    margin-right: 8px;
  }

  &:hover {
    background-color: $background-color;
  }
}

.error-message {
  color: $error-color;
  text-align: center;
  margin-bottom: 10px;
}

.register-link {
  margin-top: 15px;
  text-align: center;

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.additional-info {
  margin-top: 20px;
  text-align: center;
  color: $text-color;

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
