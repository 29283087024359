@import '../styles/_variables';

.wizard-navigation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  min-width: 200px;

  @media (max-width: $small-mobile-breakpoint) {
    display: none;
  }

  .step {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $text-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      .step-text {
        color: $primary-color;
      }
    }

    &.active {
      cursor: default;
      
      &:hover {
        .step-text {
          color: $primary-color;
        }
      }
    }
  }

  .step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    &.active {
      background-color: $primary-color;
      color: white;
    }
  }

  .step-text {
    flex: 1;

    &.active {
      color: $primary-color;
      font-weight: bold;
    }
  }
}

.mobile-progress {
  display: none;

  @media (max-width: $small-mobile-breakpoint) {
    display: block;
    text-align: center;
    padding: 10px 5px;
    font-size: 14px;
    color: $text-color;
    background-color: $gray-light;
    border-bottom: 1px solid $border-color;
  }
}