// Colors
$primary-color: #3498db;
$secondary-color: #2ecc71;
$danger-color: #e74c3c;
$warning-color: #f39c12;
$success-color: #2ecc71;
$info-color: #3498db;
$error-color: #e74c3c;

$white: #ffffff;
$black: #000000;
$gray-light: #f8f9fa;
$gray: #6c757d;
$gray-dark: #343a40;

$text-color: #333333;
$background-color: #f4f4f4;

// Typography
$font-family: 'Arial', sans-serif;
$font-size-base: 16px;
$line-height-base: 1.5;

// Spacing
$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;

// Border
$border-radius: 4px;
$border-color: #ddd;

// Shadows
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$mobile-breakpoint: 768px;
$small-mobile-breakpoint: 480px;

// Sizes
$container-width: 300px;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button-reset {
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
}