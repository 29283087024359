@import '../../styles/variables';

.admin-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

    &-content {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }

    .zone-controls {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;

        select {
            flex-grow: 1;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
    }

    .zone-details-box {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 20px;

        h3 {
            margin-top: 0;
            margin-bottom: 15px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
        }

        .zone-info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .zone-actions {
                display: flex;
                gap: 10px;
            }
        }
    }

    .sections-container {
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 20px;
    }

    .locations-section, .shifts-section {
        h3 {
            margin-top: 0;
            margin-bottom: 15px;
        }

        .button-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }
    }

    .admin-page-grid {
        display: grid;
        gap: 10px;
        align-items: center;

        &.locations-grid {
            grid-template-columns: 40px 40px 1fr auto;
        }

        &.shifts-grid {
            grid-template-columns: 40px 1fr auto;
        }

        .admin-page-grid-header {
            font-weight: bold;
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
            margin-bottom: 10px;
            display: contents;

            span {
                padding: 10px;
            }
        }

        .admin-page-grid-item {
            display: contents;

            &.selected {
                background-color: #e3f2fd;
            }

            .sort-order {
                padding: 5px;

                input[type="number"] {
                    width: 30px;
                    padding: 4px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    text-align: center;
                }
            }

            .checkbox-cell {
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                input[type="checkbox"] {
                    margin: 0;
                    width: 16px;
                    height: 16px;
                }
            }

            .selectable-location {
                padding: 10px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    background-color: #f8f9fa;
                }
            }

            .action-buttons {
                padding: 10px;
                display: flex;
                gap: 5px;
                justify-content: flex-end;
                white-space: nowrap;
            }
        }
    }

    .empty-message {
        padding: 20px;
        text-align: center;
        color: #666;
        font-style: italic;
        background-color: #f8f9fa;
        grid-column: 1 / -1;
    }

    .admin-page-button {
        padding: 6px 12px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            background-color: #0056b3;
        }

        &.delete {
            background-color: #dc3545;

            &:hover {
                background-color: #c82333;
            }
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    // Modal styles
    &-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        &-content {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            width: 100%;
            max-width: 500px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

            h3 {
                margin-top: 0;
                margin-bottom: 20px;
            }

            label {
                display: block;
                margin-bottom: 15px;

                input {
                    display: block;
                    width: 100%;
                    padding: 8px;
                    margin-top: 5px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }
            }

            .modal-actions {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                margin-top: 20px;
            }
        }
    }
}