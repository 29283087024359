@import '../styles/variables';

.publisher-information {
  &-container {
    max-width: 1200px;
    margin: 0 auto;
    font-family: $font-family;
  }

  &-header {
    background-color: $primary-color;
    color: $white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h1 {
      margin: 0;
      font-size: 24px;
      flex-grow: 1;
      text-align: center;
    }
  }

  &-content-box {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  &-page-title {
    color: $primary-color;
    margin-top: 0;
    font-size: 24px;
    text-align: center;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  &-content-layout {
    display: flex;
    gap: 20px;

    @media (max-width: $small-mobile-breakpoint) {
      flex-direction: column;
      gap: 5px;
      
      .wizard-navigation {
        order: -1;
        margin: -20px -20px 0;
        background-color: $gray-light;
      }
    }
  }

  &-main-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: $small-mobile-breakpoint) {
      width: 100%;
      padding-top: 10px;
    }
  }

  &-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-label {
    display: block;
    font-weight: bold;
  }

  &-input {
    flex-grow: 1;
    width: 100%;
    padding: 8px;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: $font-size-base;
    box-sizing: border-box;
  }

  &-publisher-entry {
    display: flex;
    gap: 10px;
  }

  &-delete-button {
    padding: 8px 12px;
    background-color: $error-color;
    color: $white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font-size-base;

    &:hover {
      background-color: darken($error-color, 10%);
    }
  }

  &-add-button {
    background-color: $primary-color;
    color: $white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font-size-base;
    margin-top: 10px;
    width: auto;
    align-self: flex-start;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  &-separator {
    height: 1px;
    background-color: $border-color;
    margin: 20px 0;
  }

  &-publishers-label {
    margin-top: 20px;
  }

  &-publishers-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &-navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
  }

  &-button {
    flex: 1;
    padding: 10px 20px;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font-size-base;
    text-align: center;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  &-additional-info {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: $text-color;

    a {
      color: $primary-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: $white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;

  .modal-button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &.cancel {
      background-color: #95a5a6;
      color: $white;
    }

    &.delete {
      background-color: $error-color;
      color: $white;
    }
  }
}

