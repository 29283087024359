@import '../styles/variables';

.profile-dialog {
  color: $text-color;

  h2 {
    color: $primary-color;
    font-size: 28px;
    margin-bottom: 30px;
  }

  .profile-info {
    margin-bottom: 30px;

    p {
      margin: 10px 0;
      font-size: 18px;
    }
  }

  .language-selector {
    margin-bottom: 30px;

    label {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 18px;
    }

    select {
      width: 100%;
      padding: 12px;
      border: 1px solid $border-color;
      border-radius: 6px;
      font-size: 16px;
    }
  }

  .dialog-buttons {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 12px 24px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-size: 18px;
      transition: background-color 0.3s ease;

      &.save-button {
        background-color: $primary-color;
        color: $white;
        margin-right: 20px;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }

      &.close-button {
        background-color: #f0f0f0;
        color: $text-color;

        &:hover {
          background-color: darken(#f0f0f0, 10%);
        }
      }
    }
  }
}