@import '../styles/variables';

.experiences {
    &-container {
        max-width: 1200px;
        margin: 0 auto;
        font-family: $font-family;
    }

    &-header {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        h1 {
            margin: 0;
            font-size: 24px;
            flex-grow: 1;
            text-align: center;
        }
    }

    &-content-box {
        background-color: $white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    &-page-title {
        color: $primary-color;
        margin-top: 0;
        font-size: 24px;
        text-align: center;
        border-bottom: 2px solid $primary-color;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    &-content-layout {
        display: flex;
        gap: 20px;

        @media (max-width: $small-mobile-breakpoint) {
            flex-direction: column;
            gap: 5px;
            
            .wizard-navigation {
                order: -1;
                margin: -20px -20px 0;
                background-color: $gray-light;
            }
        }
    }

    &-main-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (max-width: $small-mobile-breakpoint) {
            width: 100%;
            padding-top: 10px;
        }
    }

    &-form-and-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    &-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        textarea {
            width: 100%;
            height: 100px;
            padding: 8px;
            border: 1px solid $border-color;
            border-radius: 4px;
            resize: vertical;
        }
    }

    &-list {
        width: 100%;
        margin-top: 20px;
        border-top: 1px solid $border-color;
        padding-top: 20px;

        table {
            width: 100%;
            table-layout: fixed; // This ensures the table respects our column widths
            border-collapse: collapse;

            th, td {
                padding: 10px;
                border: 1px solid $border-color;
                word-wrap: break-word; // This allows long words to break and wrap
                overflow-wrap: break-word;
            }

            th:nth-child(1), td:nth-child(1) {
                width: 70%; // Experience column
            }

            th:nth-child(2), td:nth-child(2) {
                width: 20%; // Requires Attention column
            }

            th:nth-child(3), td:nth-child(3) {
                width: 10%; // Action column
            }
        }

        .experiences-no-data {
            text-align: center;
            padding: 20px;
            color: $text-color; // Using $text-color instead of $text-color-light
            font-style: italic;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    &-checkbox {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &-add-button {
        align-self: flex-start;
        padding: 8px 16px;
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: $font-size-base;

        &:hover {
            background-color: darken($primary-color, 10%);
        }
    }

    &-remove-button {
        background: none;
        border: none;
        color: $error-color;
        cursor: pointer;
        font-size: 16px;

        &:hover {
            color: darken($error-color, 10%);
        }
    }

    &-navigation-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        gap: 20px;
    }

    &-button {
        flex: 1;
        padding: 10px 20px;
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: $font-size-base;
        text-align: center;

        &:hover {
            background-color: darken($primary-color, 10%);
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &-additional-info {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        color: $text-color;

        a {
            color: $primary-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: $white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &.cancel {
        background-color: #95a5a6;
        color: $white;
    }

    &.delete {
        background-color: $error-color;
        color: $white;
    }
}
