.user-manager {
  padding: 20px;
}

.user-manager h2 {
  text-align: center;
  margin-bottom: 20px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-form {
  display: flex;
  align-items: center;
}

.search-form input,
.search-form button,
.add-user-button {
  height: 36px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #ddd;
}

.search-form input {
  padding: 0 8px;
  border-radius: 4px 0 0 4px;
  width: 200px; /* Adjust as needed */
}

.search-form button {
  padding: 0 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.add-user-button {
  padding: 0 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px; /* Fixed width */
}

.search-form button:hover,
.add-user-button:hover {
  background-color: #45a049;
}

/* Remove any default margins and paddings */
.search-form input,
.search-form button,
.add-user-button {
  margin: 0;
  line-height: 34px; /* 36px height - 2px border */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.action-buttons {
  display: flex;
  align-items: center;
}

.edit-button, .menu-button {
  margin-right: 10px;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  width: 30px; /* Fixed width for three-dot menu button */
  background-color: #ccc; /* Same color as disabled pagination buttons */
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  padding: 4px 0;
  border-radius: 4px;
  text-align: center;
}

.menu-button:hover {
  background-color: #bbb; /* Slightly darker on hover */
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 8px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input[type="text"],
.input-group input[type="email"],
.input-group input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #dddfe2;
  border-radius: 4px;
}

.input-group input[type="checkbox"] {
  margin-left: 5px;
}

.pagination-container {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination button {
  padding: 4px 8px;
  font-size: 14px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
  white-space: nowrap;
  min-width: 70px; /* Increased minimum width for "Previous" button */
  text-align: center;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #45a049;
}

.pagination select {
  margin-left: 10px;
  padding: 4px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: auto;
}

.pagination span {
  margin-left: 10px;
  font-size: 14px;
}

.results-count {
  margin-left: 20px;
  font-size: 14px;
}

.actions-header,
.actions-cell {
  width: 110px; /* Adjust this value based on your button sizes */
  min-width: 110px;
  max-width: 110px;
}

.actions-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  padding: 0 5px; /* Add some padding on the sides */
}

.edit-button {
  width: 60px; /* Fixed width */
  padding: 4px 0;
  font-size: 14px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  width: 30px; /* Fixed width for three-dot menu button */
  background-color: #ccc;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  padding: 4px 0;
  border-radius: 4px;
  text-align: center;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 8px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

/* Ensure text is centered vertically and horizontally in buttons */
.search-form button,
.add-user-button {
  display: flex;
  justify-content: center;
  align-items: center;
}