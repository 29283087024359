.activity-manager {
  padding: 20px;

  h2 {
    margin-bottom: 20px;
  }

  .search-form {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .search-filters {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .filter-column {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .filter-group {
    width: 100%;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: normal;
    }

    input[type="date"],
    input[type="text"],
    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .checkbox-group {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin-bottom: 8px;
    padding-left: 0;

    input[type="checkbox"] {
      margin: 0;
      margin-right: 8px;
    }

    label {
      margin: 0;
      display: inline;
    }
  }

  .keyword-search {
    margin-top: 10px;
    
    label {
      display: block;
      margin-bottom: 5px;
    }

    input[type="text"] {
      width: 100%;
    }
  }

  .search-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;

    &:hover {
      background-color: #0056b3;
    }
  }

  .clear-filters-button {
    width: 100%;
    padding: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #c82333;
    }

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  .activity-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    overflow-x: auto;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #e0e0e0;
      }
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    button {
      background-color: #28a745;
      color: white;
      border: none;
      padding: 5px 10px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #218838;
      }
    }
  }

  .load-more-button {
    display: block;
    margin: 20px auto;
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #5a6268;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start; // Changed from center to flex-start
    z-index: 1000;
    overflow-y: auto; // Allow scrolling
    padding: 20px 0; // Add some padding at the top and bottom
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%; /* Increased from 80% to 90% */
    max-width: 1200px; /* Added a max-width to prevent it from becoming too wide on large screens */
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    margin-top: 20px;

    // Add some spacing after the close button
    > *:not(.close-button) {
      margin-top: 20px;
    }
  }

  .close-button {
    position: sticky;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    float: right;
    color: #333; // Make it visible always
    background-color: #f8f9fa; // Light background
    border-radius: 50%; // Make it circular
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    &:hover {
      background-color: #e9ecef; // Darker on hover
      color: #000;
    }
  }

  .activity-count {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .export-section {
    margin-top: 20px;
    text-align: right;
  }

  .export-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }

  .export-button:hover {
    background-color: #45a049;
  }

  .results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}