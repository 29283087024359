@import '../styles/variables';

.submission-success {
  &-container {
    max-width: 1200px;
    margin: 0 auto;
    font-family: $font-family;
  }

  &-header {
    background-color: $primary-color;
    color: $white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h1 {
      margin: 0;
      font-size: 24px;
      flex-grow: 1;
      text-align: center;
    }
  }

  &-content-box {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-page-title {
    color: $primary-color;
    margin-top: 0;
    font-size: 24px;
    text-align: center;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 20px;
    width: 100%;
  }

  &-success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  &-success-icon {
    font-size: 48px;
    color: $success-color;
    margin-bottom: 20px;
  }

  &-thank-you {
    font-size: 24px;
    color: $primary-color;
    margin-bottom: 20px;
  }

  &-create-another-button {
    padding: 10px 20px;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font-size-base;
    text-align: center;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  &-additional-info {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: $text-color;

    a {
      color: $primary-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}